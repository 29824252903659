import React from "react"
import { Link } from "gatsby"

import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const SitemapIndex = () => (
    <>
        <SEO
            title="Sitemap"
            description="An overview with links to the pages the PKD Tech website contains."
        />

        <PageTitle title="Sitemap"/>

        <div className={"mb-10 lg:mb-32 px-6 md:px-12 mx-auto prose prose-lg"}>

            <ul className={"ml-4"}>
                <li>
                    <Link to={"/"}>Home</Link>
                </li>
                <li>
                    <Link to={"/about"}>About</Link>
                </li>
                <li>
                    <Link to={"/contact"}>Contact</Link>
                </li>
                <li>
                    <Link to={"/terms"}>Terms & Conditions</Link>
                </li>
                <li>
                    <Link to={"/privacy"}>Privacy & Cookies Policy</Link>
                </li>
            </ul>

        </div>
    </>
)

export default SitemapIndex
